import { Operation } from "./Operation";
import { OperationContext } from "../OperationContext";

export class EnableTrackChanges implements Operation<void> {
  async commit(context: OperationContext): Promise<void> {
    const document: Word.Document = context.document;
    document.load("changeTrackingMode");
    await context.sync();

    if (document.changeTrackingMode == Word.ChangeTrackingMode.off) {
      document.changeTrackingMode = Word.ChangeTrackingMode.trackMineOnly;
      await context.sync();
    }
  }

  merge(other: Operation<unknown>): Operation<void> | undefined {
    if (other instanceof EnableTrackChanges) {
      return this;
    }
  }
}
