import { WordWrapper } from "./WordWrapper";

const searchOptions = {
  matchCase: false,
  matchWholeWord: false,
  ignorePunct: true,
  ignoreSpace: true,
  matchWildcards: false,
};

/** Search for a string in the document then select each instance and run a function.
 *
 * @param search The string to search for
 * @param thunk The function to run after each selection
 */
export class SearchAndSelect {
  static async run(search: string, thunk: () => Promise<void>) {
    await WordWrapper.run(async (context) => {
      const searchResults = context.document.body.search(search, searchOptions);
      context.load(searchResults, "items");
      await context.sync();

      for (let i = 0; i < searchResults.items.length; i++) {
        const result = searchResults.items[i];
        result.select();
        await context.sync();
        await thunk();
      }
    });
  }
}
